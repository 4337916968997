import React from "react"
import SEO from "../../components/seo"
import HeroLandingPage from "../../components/pages/shared/hero/landing"
import FeaturesComponent from "../../components/pages/main/landing-features"
import PricingComponent from "../../components/pages/main/detail-pricing"
import ContactFormComponent from "../../components/pages/shared/contact-form"
import Layout from "../../components/layout"
import Banner from "../../components/pages/main/banner"
import Cards from "../../components/pages/main/cards"
import MainPageInfo from "../../components/pages/main/info"
import Discount from "../../components/pages/main/discount"
import Breadcrumb from '../../components/controls/breadcrumbs';

const KinderEductionPage = (props) => {
    const crumbs = [
        { name: 'Главная', url: '/'},
        { name: 'Beauty бизнес', url: ''}
    ];
    return (
      <Layout headerColor={"#6D20D2"} page={"landing_beautyschool"}>
          <SEO title="CRM-система для салонов красоты и студий"
               description="Уникальная программа для салона красоты, косметологии, парихмахерской. Полная оптимизация и автоматизация салона. Бесплатный тестовый период 10 дней."
               url={"https://paraplancrm.ru" + props.location.pathname}
               ogImage={"https://paraplancrm.ru/images/seo/beauty.png"}
          />
          <HeroLandingPage
            title='CRM-система учета'
            subtitle="для салонов красоты и студий"
            idUnderBlock="#features"
            offsetHeader={80}
            list={[
                '<b>Контроль и аналитика</b> бизнес-процессов.',
                '<b>Онлайн-расписание</b> и <b>авторассылки</b> WhatsApp.',
                '<b>Ускорение</b> работы администраторов.',                
            ]} />  
          <Breadcrumb crumbs={ crumbs } arrowcolor={"green"}/>
          <FeaturesComponent title='Все, что нужно' subtitle="Не только инструменты для работы с заявками и контроля отдела продаж" />
          <Discount />
          <MainPageInfo
            title='Работа с CRM - это переход на новый уровень'
            subtitle='Параплан создан специально для ведения учета клиентов и продаж салонов красоты, SPA-центров, студий массажа, эпиляции, косметологии, парикмахерских. CRM подходит для мастеров маникюра и педикюра, бровистам, лешмейкерам и визажистам.'
            ratingNumber="№2"
            ratingText="в рейтинге CRM для салонов красоты*"
          />
          <Cards />
          <Banner />
          <PricingComponent title='Тарифы' mainPage={true} mobileMenu={false} />
          <ContactFormComponent />
      </Layout>
    )
}

export default KinderEductionPage

